import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/projects/158-weymann/node_modules/@interness/web-core/src/templates/mdx/default.js";
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import HeaderImg from '@interness/web-core/src/components/media/HeaderImg/HeaderImg';
import CallToAction from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
  <HeaderImg id='158-altgold-header' mdxType="HeaderImg" />
    </HeaderImage>
    <Spacer mdxType="Spacer" />
    <Wrapper style={{
      textAlign: 'justify'
    }} width={1024} mdxType="Wrapper">
  <Heading mdxType="Heading">Service für Uhren und Schmuck</Heading>
      <p>{`  Auch nach dem Kauf Ihres Schmuckstücks oder Ihrer Uhr sind wir für Sie da.
Mit größter Sorgfalt und fachgerechtem Geschick sorgen wir dafür, dass Sie lange Freude an Ihren Kostabarkeiten
haben.
In keiner Phase der Reparatur oder der Revision verlieren wir unnötige Zeit. Je nach Aufwand ist es am Ende nicht
die Schnelligkeit, die uns treibt, Ihre Schmuck- oder Uhrreparatur fertig zu stellen, vielmehr unser Streben zu Ihrer
Zufriedenheit.
Sie haben einen anderen Wunsch? Sprechen Sie uns an!`}</p>
  <Spacer mdxType="Spacer" />
  <Heading tag='h2' mdxType="Heading">Uhrenservice</Heading>
  <Heading tag='h3' mdxType="Heading">Investition in Ihre Zeit</Heading>
  Die Pflege Ihrer Uhr ist uns auch nach dem Kauf wichtig. Daher sorgen wir uns um den Service Ihrer Zeitmesser mit
  größter Sorgfalt.
  Armbanduhren sind hochentwickelte Präzisionsinstrumente, die ununterbrochen arbeiten. Auf kleinstem Raum
  gewährleisten
  eine Vielzahl von Werkbestandteilen alle Zeit- und Zusatzfunktionen. Dabei sind mechanische Verschleißerscheinungen
  unvermeidlich.
  Sobald wir Ihre Uhr in Händen halten, laufen alle nötigen Schritte von der Beurteilung bis zur Endkontrolle nach
  einer
  bis ins kleinste Detail definierten Fehlerdiagnose ab. Dabei sind fachspezifisches Handwerksgeschick, uhrmacherische
  Erfahrung und außerordentliche Gründlichkeit gefragt. Hiernach erstellen wir ein optimal auf Ihre Uhr
  zugeschnittenes
  Reparaturangebot, damit diese Ihnen auch in Zukunft ein zuverlässiger Partner bleibt.
  <Spacer mdxType="Spacer" />
  <Heading tag='h3' mdxType="Heading">Unser Leistungsspektrum:</Heading>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Totalüberholungen von mechanischen Uhren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Totalüberholungen/ Werkersatz quarzgesteuerten Armbanduhren und Großuhren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Batteriewechsel`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Teilreparaturen wie Glasersatz, Krone und Welle ersetzen, Bandreparatur, etc.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Band und Gehäusereinigung an Armbanduhren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Leder- und Metallbänder für Armbanduhren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Kürzen/Verlängern von Metallbändern`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Totalüberholungen von Großuhren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Servicevermittlung von Luxusuhren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Homeservice: Hol- und Bringdienst für Ihre Großuhren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Begutachten und Berwerten von Uhren und Schmuck für Versicherung und Erbfall`}</p>
          <p parentName="li">{`Sie haben einen anderen Wunsch? Sprechen Sie uns an!
In keiner Phase der Reparatur oder der Revision verlieren wir unnötige Zeit. Je nach Aufwand ist es am Ende nicht
die
Schnelligkeit, die uns treibt, Ihre Uhrreparatur fertig zu stellen, vielmehr unser Streben zu Ihrer Zufriedenheit.`}</p>
          <Spacer mdxType="Spacer" />
          <Heading tag='h3' mdxType="Heading">Wasserdichtigkeit</Heading>
Die Haltbarkeit der Wasserdichtheit einer Uhr ist begrenzt und wird durch den täglichen Gebrauch der Uhr
beeinflusst.
Ein positives Prüfergebnis bescheinigt die Wasserdichtheit zum Zeitpunkt der Übergabe der Uhr. Jegliches Risiko
bezüglich der Wasserdichtheit der Uhr trägt der Besitzer alleine. Wir empfehlen deshalb, die Uhr regelmäßig auf
Wasserdichtheit überprüfen zu lassen und, falls erforderlich, einen Dichtungswechsel durchführen zu lassen.
          <Spacer mdxType="Spacer" />
          <Heading tag='h2' mdxType="Heading">Schmuckservice</Heading>
          <Heading tag='h3' mdxType="Heading">Kostbares erhalten</Heading>
Gold, Silber, Palladium und Platin - diese wertvollen Edelmetalle tragen einen magischen Glanz. Auch funkelnde
Diamanten, schimmernde Perlen und die Farbenvielfalt echter Edelsteine faszinieren seit jeher die Menschen.
          <p parentName="li">{`Heutzutage tragen Sie Schmuck vor allem, um Ihrem Empfinden für Schönheit und Ästhetik Ausdruck zu verleihen und die
eigene Persönlichkeit zu unterstreichen.`}</p>
          <p parentName="li">{`Eine regelmäßige Pflege und Reinigung unterstützt die Lebensdauer Ihres Schmuckstückes und erhält das Funkeln und
die sichere Fassung von Edelsteinen. Der täglichen Beanspruchung ausgesetzt verändern sich Oberflächen und Steine im
Laufe ihrer Tragezeit. In unserer Goldschmiedewerkstatt kümmern wir uns um eine optimale Betreuung und um die Reparatur
und Erhaltung Ihrer Lieblingsstücke.`}</p>
          <Spacer mdxType="Spacer" />
          <Heading tag='h3' mdxType="Heading">Unser Leistungsspektrum:</Heading>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Reinigen und Pflegen von Schmuck`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Aufarbeiten von Schmuckstücken`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Schmuckreparaturen wie z.B. Ringweitenänderungen und Lötungen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Anfertigungen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Umarbeiten von Trauringen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Gravuren`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Fädeln und Knoten von Perlen und Steinketten`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Ohrlochstecken`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`seriöser Altgoldankauf (Schmuckgold und - silber, Zahngold)`}</p>
          <p parentName="li">{`Mit Sorgfalt, fachgerechtem Geschick und unseren umfangreichen Fachkenntnissen
sorgen wir, die Goldschmiede und Schmuckfachleute dafür, dass Sie lange Freude an Ihren Kostbarkeiten haben.`}</p>
          <Spacer mdxType="Spacer" />
        </li>
      </ul>
    </Wrapper>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      